<template>
  <div>
    <div class="ps-block--vendor-dashboard">
      <div class="ps-block__header">
        <h3>Orders</h3>
      </div>
      <div class="ps-block__content">
        <div class="ps-form--vendor-datetimepicker">
          <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="time-from">From</span>
                </div>
                <input class="form-control" aria-describedby="time-from" v-model="filters.from_date" />
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="time-form">To</span>
                </div>
                <input class="form-control" aria-describedby="time-to" v-model="filters.to_date" />
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
              <button type="button" class="ps-btn" @click="getOrders"><i class="icon-sync2"></i> Submit</button>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table ps-table ps-table--vendor table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>Order ID</th>
                <th>Total Price</th>
                <th>Status</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in orders" :key="order.id">
                <td>{{ order.created_at|formatDate }}</td>
                <td><span class="text-muted">{{order.order_number}}</span></td>
                <td>{{ order.total_amount | toCurrency }}</td>
                <td>
                  <span v-if="order.status.name === 'paid'" class="text-success">{{ order.status.label }}</span>
                  <span v-else class="text-danger">{{ order.status.label }}</span>
                </td>
                <td>
                  <router-link :to="{name: 'admin_order_detail', params: {id: order.id}}"><i class="icon-eye"></i></router-link>
                </td>
              </tr>
            </tbody>
            <tfoot>
            <tr v-if="(!meta || meta.total === 0) && !isLoading">
              <td colspan="6" class="text-center m-4 text-danger p-4">No orders available</td>
            </tr>
            <tr v-else-if="isLoading">
              <td colspan="6" class="text-center m-4 text-danger">Loading orders...</td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      filters: {
        from_date: null,
        to_date: null
      },
      orders:[],
      meta: null,
      isLoading: false
    };
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      this.isLoading = true
      axios.get('orders').then((response) => {
        this.isLoading = false
        this.orders = response.data.data
        this.meta = response.data.meta
      }).catch((error) => {
        this.isLoading = false
      });
    },
  },
};
</script>